/* #app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
} */

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.mySwiper {
  /* display: flex;
  align-items: center; */
  width: 100%;
  height: 100%;
  background: black;

}

.swiper-wrapper {
  display: flex;
  align-items: center;
}

.swiper-slide {
  /* max-height: 15%; */
  text-align: center;
  font-size: 18px;
  background: black;
  /* object-fit: cover; */

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.inner-swiper-slide {
  height: 65% !important;
}

.inner-swiper-slide img {
  display: block;
  width: 100%;
  height: 100% !important;
  object-fit: contain;
  margin: auto;
}

@media screen and (min-width: 1024px) {
  .swiper-slide {}

  .swiper-slide img {}


}