html, body, .App {
  width: 100%;
  height: 100%;
  /* display: flex; */
  /* flex-direction: column; */
  /* margin: 0; */
}

#root {
  height: 100%;
  min-height: 100%;
}

.main {
  height: 80%;
  /* height: 100%; */
  margin-bottom: 80px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.header, .footer {
  flex-shrink: 1;
}

@media screen and (min-width: 1024px) {
  .App {
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  .main {
    height: 100%;
    margin-bottom: 0;
  }



}