@font-face {
    font-family: "custom_4";
    src: url("../../assets/fonts/ITCAvantGardeStdDemi.otf")
}

@font-face {
    font-family: "custom_3";
    src: url("../../assets/fonts/ITCAvantGardeStdMdCn.otf")
}

@font-face {
    font-family: "custom_5";
    src: url("../../assets/fonts/ITCAvantGardeStdBkObl\ 2.otf")
}


.biographyContainer {
    /* display: flex;
    flex-direction: column; */
}

.titleBiography {
    font-size: 3rem;
    margin: 48px 16px 32px;
    font-family: "custom_4";
}

.subtitleBiography {
    color: grey;
    margin: 32px 16px;
    font-family: "custom_4";
}

.containerPicture {
    height: auto;
    width: 40%;
    float: left;
    margin: 16px 16px 0;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
}

.picture {
    height: 100% !important;
    width: 100%;
}

.quoteText {
    margin: 16px;
}

.quoteText b {
    font-size: 16px;
    font-family: "custom_4";
    line-height: 1;
}

.text {
    color: black;
    font-size: 12px;
    margin: 16px 16px;
    font-family: "custom_3";

}

.last {
    margin-bottom: 72px;
}

@media screen and (min-width: 1024px) {
    .biographyContainer {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 0 64px;
        margin-bottom: 80px;
    }

    .titleContainer {
        /* height: 31vh; */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        /* align-items: flex-start; */
        align-self: flex-end;
        margin-bottom: 64px;
    }

    .titleBiography {
        font-size: 3rem;
        margin: 48px 0 32px;
        font-family: "custom_4";
    }
    
    .subtitleBiography {
        color: grey;
        margin: 32px 0;
        font-family: "custom_4";
    }

    .containerPicture {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* background: red; */
    }

    .picture {
        height: auto !important;
        max-width: 64%;
        max-height: 100%;
        object-fit: cover;
    }

    .contentContainer {
        height: fit-content;
        width: min-content;
    }

    .quoteText {
        margin: 64px 0 60px 0;
        width: 60%;
    }

    .quoteText b {
        font-size: 2rem;
    }

    .text {
        font-size: 18px;
        margin: 32px 0 32px 80px;
        width: 35vw;
    }
}