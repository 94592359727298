.headerContainer {
    /* position: fixed;
    top: 0; */
    width: 100%;
    border-bottom: 1px solid black;
    background-color: whitesmoke;
}

.innerHeaderContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
    height: 64px;
}

img {
    height: 40px !important;
    width: auto;
}