@font-face {
    font-family: "custom_1";
    src: url("../../assets/fonts/Schnyder_L_Bold.otf")
}
@font-face {
    font-family: "custom_2";
    src: url("../../assets/fonts/ITCAvantGardeStdBkObl\ 2.otf")
}

@font-face {
    font-family: "custom_3";
    src: url("../../assets/fonts/ITCAvantGardeStdDemi.otf")
}

@font-face {
    font-family: "custom_5";
    src: url("../../assets/fonts/Escape\ the\ Ordinary.ttf")
}

.containerBackground {
    height: 80vh;
    width: 80vh;
}

.containerBackground img {
    display: flex;
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    pointer-events: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 94vh !important;
    width: auto;
}

.homeContainer {
    flex-grow: 1;
    max-width: 100vw;
    margin: 0 12px;
}

.innerHomeContainer {
    display: flex;
    flex-direction: row;
    margin-top: 32px;
}

.items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 8px;
    margin-bottom: 112px;
}

.item {
    font-size: 2rem;
    text-decoration: none;
    color: black;
    font-family: "custom_1" !important;
    font-size: 33px;

}

.item:hover {
    color: black;
}

.item::after {
    content: "/";
    margin: 0 8px;
}

.item:last-child::after {
    content: "";
}

@media screen and (min-width: 1024px) {
    .items {
        margin-top: 32px;
        margin-bottom: 112px;
        width: 42%;
        height: fit-content;
    }

    .item {
        font-size: 50px;
        margin-bottom: 32px;
    }

    .slash {
        font-size: 3rem;
    }
}