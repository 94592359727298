@font-face {
    font-family: "custom_6";
    src: url("../../assets/fonts/Social\ Gothic\ DemiBold.otf")
}

@font-face {
    font-family: "custom_7";
    src: url("../../assets/fonts/ITCAvantGardeStdMdCn.otf")
}


.contactContainer {
    /* flex-grow: 1; */
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentContactInnerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
}

.titleContact {
    font-size: 4rem;
    margin: 84px 16px 32px;
}

.secondTitleContact {
    font-size: 3.8rem;
    margin: 24px 16px 32px;
    text-align: center;
}

.subtitle {
    font-size: 20px;
    margin: 24px 16px 0 16px;
    width: fit-content;
    font-family: "custom_6";
}

.content {
    font-size: 16px;
    margin: 0 16px;
    width: fit-content;
    text-align: center;
    font-family: "custom_7";

}

.linkContainer {
    display: flex;
    flex-direction: column;
    text-align: center;

}

.link {
    font-size: 16px;
    margin: 0 16px;
    color: black;
    text-decoration: none;
    text-align: center;
    font-family: "custom_7";
}

.link:active, .link:hover {
    color: black;
}

@media screen and (min-width: 1024px) {

    .contentContactContainer {
        display: flex;
        flex-direction: column;
        width: 79%;
        margin-bottom: 80px;
    }

    .titleContact {
        font-size: 7rem;
    }

    .secondTitleContact {
        font-size: 7rem;
        text-align: start;
    }
}