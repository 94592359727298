@font-face {
    font-family: "custom_5";
    src: url("../../assets/fonts/Social\ Gothic\ DemiBold.otf")
}

nav {
    margin: 0;
}

.navList {
    display: flex;
    flex-direction: row;
    margin: 0;
}

.navItem {
    list-style-type: none;
    margin-left: 10px;
}

.navlink {
    color: black;
    text-decoration: none;
    font-family: "custom_5", serif !important;

}

.navlink:hover {
    color: black;
    text-decoration: underline 2px solid black;
}

.navlink:active {
    text-decoration: underline 2px solid black;
}