/* .containerSlider {
    margin-top: 64px;
}

#carouselProject {
    margin-top: 64px;
}

.carousel-item {
    height: 90vh;
    background-color: black;
}

.imgItem {
    object-fit: contain;
}

.buttonNext, .buttonPrevious {
    width: 50%;
    opacity: 0;
    background: rgba(0, 0, 0, 0.0);
}

.buttonNext:hover, .buttonPrevious:hover, .buttonNext:focus, .buttonPrevious:focus {
    opacity: 0;
}

.buttonNext {
    z-index: 100;
    cursor: url(../../assets/icons/chaille_fleche_droite.png), auto !important;
}

.buttonPrevious {
    z-index: 100;
    cursor: url(../../assets/icons/chaille_fleche_gauche.png), auto !important;
} */


.containerSlider {
    height: 100%;
}

/* #carouselProject {
    margin-top: 64px;
} */

.carousel {
    height: 100%;
}

.carousel-inner {
    height: 100%;
    background-color: black;
    display: flex;
    align-items: center;
}

.carousel-item {
    height: 80vh;
    background-color: black;
}

.imgItem {
    height: 100%;
    object-fit: contain;
}

.buttonNext, .buttonPrevious {
    width: 50%;
    opacity: 0;
    background: rgba(0, 0, 0, 0.0);
}

.buttonNext:hover, .buttonPrevious:hover, .buttonNext:focus, .buttonPrevious:focus {
    opacity: 0;
}

.buttonNext {
    cursor: url(../../assets/icons/chaille_fleche_droite.png), auto !important;
}

.buttonPrevious {
    cursor: url(../../assets/icons/chaille_fleche_gauche.png), auto !important;
}
