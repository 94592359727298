@font-face {
    font-family: "custom_2";
    src: url("../../assets/fonts/Social\ Gothic\ DemiBold.otf")
}

.footerContainer {
    height: 40px !important;
    width: 100%;
    border-top: 1px solid black;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: whitesmoke;
    z-index: 1000;
}

.innerFooterContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin: 0 8px;
}

.contentFooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 10px;
    font-family: "custom_2";
}

p {
    margin: 0;
}

.contentFooter a {
    color: black;
    text-decoration: none;
    margin-left: 8px;
}

.socialList {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.socialItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    margin-left: 8px;
}

.linkItem {
    text-decoration: none;
    color: black;
    font-size: 10px;
    font-family: "custom_2";
}

.linkItem:hover {
    color: black;
}

@media screen and (min-width: 1024px) {
    .contentFooter {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 16px;
    }

    .linkItem {
        font-size: 16px;
    }

    .innerFooterContainer {
        margin: 0 16px;
    }
}