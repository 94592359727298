.container-project {
    height: 100%;
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 1024px) {
    .container-project {
        margin-bottom: 80px;
    }

}
