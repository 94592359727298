@font-face {
    font-family: "custom_2";
    src: url("../../assets/fonts/Social\ Gothic\ DemiBold.otf")
}

.appBarContainer {
    height: 40px !important;
    width: 100%;
    border-top: 1px solid black;
    background-color: whitesmoke;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    position: fixed;
    left: 0;
    bottom: 40px;
}

.appBarContainer p {
    font-size: 12px;
    font-family: "custom_2";
}

@media screen and (min-width: 1024px) {
    .appBarContainer p {
        font-size: 16px;
    }
}